


















import Vue from 'vue'
import MenuVerticalIcon from '@/assets/icons/menu-vertical.svg' 
import ToggleButton from '@/components/ToggleButton.vue'
import { UserAccountState } from '@/interfaces'
export default Vue.extend({
  components:{
    MenuVerticalIcon,
    ToggleButton
  },

  props: {
    rowData:{
      type: Object,
      default(){
        return {}
      }
    },

    onChangeClick: {
      type: Function,
      default(event: any, rowData: any){
        // 
      }
    },

    onConfirmEvent: {
       type: Function,
      default(event: any, rowData: any){
        // 
      }
    }
  },

  data(){
    return {
      open: false,
    }
  },

  computed: {
    isLocked(){
      return this.rowData.accountStatus ===  UserAccountState.DISABLE
    }
  }
})
