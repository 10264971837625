










































































































import Vue from 'vue'
import SearchIcon from '@/assets/icons/search.svg'
import VTable from '@/components/VTable/VTable.vue'
import { DateTime } from "luxon";
// import TablePagination from '@/components/TablePagination.vue';
import ToggleButton from '@/components/ToggleButton.vue';
import SupervitionMobileTableMenu from './components/SupervitionMobileTableMenu.vue';
import { EMPTY_TABLE_MESSAGE, SOCKET_EVENTS, UserAccountState } from '@/interfaces';
import StateFilter from '@/components/StateFilter.vue';
import { socket } from '@/plugins';
import VTablePagination from '@/components/VTable/VTablePagination.vue';


interface Test  {
  setPagination: Function;
}

export default Vue.extend({

  components:{
    SearchIcon,
    VTable,
    // TablePagination,
    ToggleButton,
    SupervitionMobileTableMenu,
    StateFilter,
    VTablePagination
  },

  data(this: any){
    return {
      search: '',
      appendParams: {},
      mobileTableMenuOpen: false,
      showModalAction: false,
      loading: false,
      toggleAction: false,
      toggleEvent: null,
      current: null,
      columns: [
        {
          name: 'firstName',
          title: 'Utilisateur',
          transform({firstName, lastName}: any){
            return firstName + ' ' + lastName
          }
        },
        {name: 'createdAt', title: 'Date de création',
        transform({createdAt}: any){
            if (!createdAt) {
              return ''
            }
            const dt = DateTime.fromISO(createdAt)
            return dt.toLocaleString(DateTime.DATETIME_SHORT)
          }
        },
        {name: 'email', title: 'Email'},
        {
          name: 'telephone',
          title: 'Téléphone',
          transform: this.formatPhone
        },
        {name:'__slot:accountStatus', title: 'Action'}
      ],
      columnsMobile: [
        {
          name: 'firstName',
          title: 'Utilisateur',
          transform({firstName, lastName}: any){
            return `<div>${firstName}  ${lastName}</div>`
          }
        },
        {
          name: 'telephone',
          title: 'Téléphone',
          transform: (rowData: any) => this.formatPhone(rowData)
        },
        {name:'__slot:accountStatus', title: 'Action'}
      ],
      customers: []
    }
  },

  methods: {
    formatPhone({telephone, countryCode}: any){
      let phone: string = telephone;
      if (!phone) {
        return ''
      }
      if (phone.charAt(0) == '+') {
        return phone
      }
      phone = `${countryCode? '+' + countryCode: '' }${telephone}`;
      return phone
    },

    displayConfirmationMessage(current: any): string{
      const div = `Voulez-vous vraiment ${current.accountStatus === 'active' ?  '<span class="font-bold">bloquer</span>': '<span class="font-bold">réactiver</span> '} l'utilisateur
      ${current.firstName} ${current.lastName} (${current.email}) et les compteurs qui lui sont liés ?`
      return div
    },

    onConfirmation(event: MouseEvent, rowData: any){
      event.preventDefault()
      this.$data.current = rowData;
      this.$data.showModalAction = true
      console.log('rowData ', rowData);
    },


    onConfirmAction(event: any, rowData: any){
      //
      const userId = rowData._id;
      this.$data.loading = true
      this.$store.dispatch('customers/changeAccountState',{userId})
      .then(response => {
        console.log('account status', response);
        this.$data.showModalAction = false
        this.refreshTables()

      })
      .catch(error => {
        console.log('error account status change ', error);

      })
      .finally(() => { this.$data.loading = false})
    },

    cancelAction(){
      this.$data.showModalAction = false
    },

    onResetFilted(this: any){
      this.appendParams.accountStatus = ''

      this.emptyDataMessage = EMPTY_TABLE_MESSAGE.default
      this.refreshTables()
    },

    onFilteredState(this: any, event: any){
      //
      console.log('event: ', event);
      console.log('event ', event);
      this.appendParams.accountStatus = event
      this.emptyDataMessage = EMPTY_TABLE_MESSAGE.filter
      this.refreshTables()

    },

    loadCustomerOnApiMode(this: any, {options}: any) {
      const params = options['params']
      console.log('params -->', params);
      return new Promise((resolve, reject) => {
        this.$store.dispatch('customers/getAllCustomer', params)
        .then((response: any) => {
          console.log('customers ', response);

          resolve({data: response})
        })
        .catch((error: any) => {
          console.log('error ', error);
          reject(error)
        })
      })
    },

    onPaginationChange(page: number){
      // const current_page = this.$refs.pagination.currentPage();
      console.log('load page -->', page);

      this.changePage(page)
    },

    onPaginationData(paginationData: any){
      console.log('Operation paginationData: ====>', paginationData);
      //@ts-ignore
      this.$refs.tablePagination.setPagination(paginationData)
    },

    onChangePage(page: number | string) {
      console.log('change-page', page);

      //@ts-ignore
      this.$refs.table.changePage(page)
    },

    changePage(this: any, page: number){
      this.$store.dispatch('customers/getAllCustomer', {page}).then((response: any) => {
          console.log('customers ', response);
          this.customers = response.data
        })
        .catch((error: any) => {
          console.log('getAllCustomer ', error);
        })
    },

    changeCustomerAccountState(event: any, data: any){
      console.log('event ', event);
      console.log('data', data);
      const userId = data._id;
      this.$store.dispatch('customers/changeAccountState',{userId})
      .then(response => {
        console.log('account status', response);
      })
      .catch(error => {
        console.log('error account status change ', error);

      })
    },

    isAccountLocked(rowData: any){
      // console.log('isAccountLocked', rowData.accountStatus === UserAccountState.DISABLE);

      return rowData.accountStatus === UserAccountState.DISABLE
    },

    onSearch(this: any, search: string){
      // console.log('search ', search);
      this.appendParams.search = search;
      if (search.length > 0) {
        this.emptyDataMessage = EMPTY_TABLE_MESSAGE.search
      }else {
        this.emptyDataMessage = EMPTY_TABLE_MESSAGE.default
      }
      this.refreshTables()
    },

    refreshTables(){
      //@ts-ignore
      this.$refs.table.refresh();
      //@ts-ignore
      this.$refs.mobileTable.refresh()
    },
  },

  computed: {
    filterItems(){
      return [
        {
          name: "Débloqués",
          key: 'active',
          callback(this: any, item: any){
            this.$emit('filtered', item.key)
          }
        },
        {
          name: 'Bloqués',
          key: 'disable',
          callback(this: any, item: any){
            this.$emit('filtered', item.key)
          }
        },
      ]
    }
  },

  mounted(){
    // this.loadCustomerOnApiMode({})
    socket.on(SOCKET_EVENTS.USER_ACCOUNT_STATE_CHANGE, (event) => {
      console.log('account user change event ======>>>>>>>>>>', event);

    })
  }
})
