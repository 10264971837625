



















import Vue from 'vue'
export default Vue.extend({
  inheritAttrs: false,
  props: {
    checked:{
      type: Boolean,
      default: false
    },

    confirm: {
      type: Function,
      default(event: MouseEvent, rowData?: any){
        if (confirm('Voulez-vous vraiment blocker cet utilisateur ?')) {
          console.log('confirmed');
          
          this.$emit('input', event.target?.dispatchEvent)
        }else {
          event.preventDefault()
        }
      }
    },

    label:{
      type: [Array],
      default() {
        return ['Toggle off', 'Toggle on']
      }
    },
    
    name:{
      type: String,
      default: 'toggle'
    },

    id:{
      type: String,
    },
  },

  data(){
    return {
      check: this.checked
    }
  },

  methods: {
    toggleInput(event: MouseEvent) {
      // console.log('mouse down --->');
      
      const input = this.$el.getElementsByClassName('toggle-checkbox')[0] as HTMLInputElement
      
      console.log('target ', event.target);
      if (event.target !== input) {        
        input.click()
      }
    }
  },

  computed: {
    innerChecked(this: any){
      return this.check
    },

    events(this: any){
      return {
        ...this.$listeners,
        change: (event: any) => {
          
          this.check = event.target.checked
          
          this.$emit("input", event.target.checked);
          this.$emit("change", event.target.checked);
        },
        input: (event: any) => {
          // 
        },

        click: (event: MouseEvent) => {
          
          this.$emit('confirm', event)
        }
      }
    },
    bindProps(){
      const bind: any = {
        checked: this.innerChecked
      }
      if (this.id) {
        bind.id = this.id
      }
      return bind
    }
  },

  watch: {
    checked(this: any, val){
      console.log('change ', val);
      
      this.check = val
    }
  }
})
