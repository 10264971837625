



















import Vue from 'vue'
import Calendar from '@/assets/icons/calendar.svg';
import DropDrown from '@/assets/icons/dropdown.svg';
export default Vue.extend({
  props: {
    items: {
      type: Array,
      default(){
        return []
      }
    },

    resetFilted:{
      type: Function,
      default(){
        // 
      }
    }
  },

  components: {
    Calendar,
    DropDrown,
  },

  data(){
    return {
      filterBy: 'Filtrer par état',
      open: false,
    }
  },

  methods: {
    toggleDropDown(this: any, event: any): void{
      
      event.preventDefault();
      
      const input = this.$el.getElementsByClassName('input-filter')[0] as HTMLInputElement;
      if (!this.open) {
        input.focus()
      }else{
        input.blur()
      }
      
    },

    focus(this: any){
      this.open = true
    },

    blur(this: any){
      this.open = false
    },

    handleResetFilted(this: any): void{
      this.$data.filterBy = 'Filtrer par état';
      this.blur()
      this.resetFilted()
    },

    emitKey(this: any, item: any): void{
      console.log('mouse down', item);
      this.$data.filterBy = item.name;
      this.blur()
      if (typeof item.callback === 'function') {
        item.callback.call(this, item)
        // item.callback(item) DO NOT WORK        
        return
      }
      this.$emit('filtered', item.key)
    },

  },
  computed: {
    inputStyles(){
      const style = {
        width: `${this.$data.filterBy.length}ch;`
      }
      console.log('length word ', this.$data.filterBy.length);
      
      return {width: `${this.$data.filterBy.length}ch;`}
    },
  }
})
